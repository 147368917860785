<template>
    <div class="page_container" :class="{'straight_length': borderPosition}">
        <div class="page_head">
            <div class="page_head-item">
                <div class="page_head-title">
                    LEAD STATUSES
                </div>

            </div>
            <div class="page_head-item">
                <b-button class="page_head-new-btn" @click="newStatus()" id="status-btn">+Add New Status</b-button>
            </div>
        </div>
        <div class="page_content">
            <b-table hover :items="allStatuses" :fields="fields" responsive="sm">
                <template v-slot:cell(name)="data">
                    <div class="item_name item_center" :style="{color: data.item.color}">
                        {{data.item.name}}
                    </div>
                </template>
                <template v-slot:cell(lead_type)="data">
                    <div class="item_center">
                        {{getLeadType(data.item.lead_type)}}
                    </div>
                </template>

                <template v-slot:cell(edit)="data">
                    <div class="item_edit" id="status_edit" @click="statusEdit(data.item)">
                    </div>
                </template>
                <template v-slot:cell(delete)="data">
                    <div class="item_delete" id="status_delete" @click="statusDeleteModal(data.item)">
                    </div>
                </template>
            </b-table>
        </div>

<!--Add Status modal start-->
        <b-modal ref="status-modal" centered hide-header hide-footer hide-header-close >
            <div class="status_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="addStatusModal()"></b-icon>
                <div class="d-flex">
                    <div class="status_title">
                        ADD NEW STATUS
                    </div>
                </div>

                <div class="input_content">

                    <div class="single_input">
                        <div class="input_item">
                            <label>Status</label>
                            <b-form-input
                                    placeholder="Text..."
                                    v-model="add_status_data.name"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="createStatusErrors['name']" class="text_danger mt-1">{{createStatusErrors['name'][0]}}</span>
                        </div>
                    </div>
                    <div class="single_input">
                        <div class="input_item">
                            <label>Color</label>
                            <b-form-input
                                    placeholder="enter location"
                                    v-model="add_status_data.color"
                                    type="color"
                                    trim
                            ></b-form-input>
                            <span v-if="createStatusErrors['color']" class="text_danger mt-1">{{createStatusErrors['color'][0]}}</span>
                        </div>
                    </div>

                    <div class="single_input">
                        <div class="input_item">
                            <label>Lead type</label>
                            <div class="select_arrow">
                                <b-form-select v-model="add_status_data.lead_type">
                                    <template #first>
                                        <b-form-select-option value="2">All Leads</b-form-select-option>
                                    </template>
                                    <option
                                            v-for="(option, id) in leadType"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.type || null"
                                    >
                                        {{option.type}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <span v-if="createStatusErrors['lead_type']" class="text_danger mt-1">{{createStatusErrors['lead_type'][0]}}</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="done_btn-container">
                    <b-button class="done_btn" @click="addNewStatus()">Add Status</b-button>
                </div>
            </div>
        </b-modal>
<!--Add Status modal end-->

<!--Edit Status modal start-->
        <b-modal ref="status_edit-modal" centered hide-header hide-footer hide-header-close >
            <div class="status_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="statusEditModal()"></b-icon>
                <div class="d-flex">
                    <div class="status_title">
                        Edit STATUS
                    </div>
                </div>

                <div class="input_content">

                    <div class="single_input">
                        <div class="input_item">
                            <label>Status</label>
                            <b-form-input
                                    placeholder="Text..."
                                    v-model="status_edit_data.name"
                                    type="text"
                                    trim
                            ></b-form-input>
                            <span v-if="createStatusErrors['name']" class="text_danger mt-1">{{createStatusErrors['name'][0]}}</span>
                        </div>
                    </div>
                    <div class="single_input">
                        <div class="input_item">
                            <label>Color</label>
                            <b-form-input
                                    placeholder="enter location"
                                    v-model="status_edit_data.color"
                                    type="color"
                                    trim
                            ></b-form-input>
                            <span v-if="createStatusErrors['color']" class="text_danger mt-1">{{createStatusErrors['color'][0]}}</span>
                        </div>
                    </div>

                    <div class="single_input">
                        <div class="input_item">
                            <label>Lead type</label>
                            <div class="select_arrow">
                                <b-form-select v-model="status_edit_data.lead_type">
                                    <template #first>
                                        <b-form-select-option value="2">All Leads</b-form-select-option>
                                    </template>
                                    <option
                                            v-for="(option, id) in leadType"
                                            :key="id"
                                            :value="option.id"
                                            :title="option.type || null"
                                    >
                                        {{option.type}}
                                    </option>
                                </b-form-select>
                                <div class="arrow_down"></div>
                                <span v-if="createStatusErrors['lead_type']" class="text_danger mt-1">{{createStatusErrors['lead_type'][0]}}</span>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="done_btn-container">
                    <b-button class="done_btn" @click="editStatus()">Edit Status</b-button>
                </div>
            </div>
        </b-modal>
<!--Edit Status modal end-->

<!--Status delete modal start-->
        <b-modal ref="status_delete" centered hide-header hide-footer hide-header-close>
            <div class="status-delete_modal_content">
                <b-icon icon="x" class="x_btn" aria-hidden="true" @click="statusDeleteModal()"></b-icon>
                <div class="d-flex">
                    <div class="status-delete_title">
                        You are about to delete this status
                    </div>
                </div>
                <div class="status-delete_message">
                    Do you want to proceed?

                </div>

                <div class="delete_btn-container">
                    <b-button class="delete_btn" @click="deleteStatus()">Delete</b-button>
                </div>
            </div>
        </b-modal>
<!--Status delete modal end-->


    </div>
</template>

<script>
    import {mapActions} from "vuex";

    export default {
        name: "LeadStatuses",
        data() {
            return {
                borderPosition: true,
                createStatusErrors: {},
                add_status_data: {
                    name: null,
                    color: null,
                    lead_type: ''
                },
                status_edit_data: {
                    name: null,
                    color: null,
                    lead_type: ''
                },
                leadType: [
                    {
                        type: 'Seller',
                        id: 1
                    },
                    {
                        type: 'Buyer',
                        id: 0
                    }
                ],
                fields: [
                    {
                        key: 'name',
                        label: 'STATUS',
                    },
                    {
                        key: 'lead_type',
                        label: 'LEAD TYPE',
                    },
                    {
                        key: 'edit',
                        label: 'EDIT',
                    },
                    {
                        key: 'delete',
                        label: 'DELETE',
                    },
                ],
                allStatuses: [],
                remove_item: ''
            }
        },
        created() {
            alert.$on("clickOn",border =>{
                this.borderPosition = border;
            })
            this.getAllStatuses()
            .then(res => {
                if(res.status === 200) {
                    this.allStatuses = res.data.success
                }
            })
        },
        methods: {
            ...mapActions(['getAllStatuses', 'createStatus', 'removeStatus', 'status_edit']),
            newStatus() {
                this.add_status_data.name = null
                this.add_status_data.color = null
                this.add_status_data.lead_type = ''
                this.addStatusModal()
            },
            addStatusModal() {
              this.$refs['status-modal'].toggle('#status-btn')
            },
            addNewStatus() {
                this.createStatus(this.add_status_data)
                .then(res => {
                    if(res.status === 200) {
                        this.addStatusModal()
                        this.getAllStatuses()
                            .then(res => {
                                if(res.status === 200) {
                                    this.allStatuses = res.data.success
                                }
                            })
                    }
                })

            },
            statusEdit(item) {
                this.status_edit_data.name = item.name
                this.status_edit_data.color = item.color
                this.status_edit_data.lead_type = item.lead_type
                this.status_edit_data.id = item.id
                this.statusEditModal()
            },
            statusEditModal() {
                this.$refs['status_edit-modal'].toggle('#status_edit')
            },
            editStatus() {
              this.status_edit(this.status_edit_data)
                .then(res => {
                    if(res.status === 200) {
                        this.statusEditModal()
                        this.getAllStatuses()
                            .then(res => {
                                if(res.status === 200) {
                                    this.allStatuses = res.data.success
                                }
                            })
                    }
                })
            },
            statusDeleteModal(item) {
                if(item) {
                    this.remove_item = item.id
                }
                this.$refs['status_delete'].toggle('#status_delete')
            },
            deleteStatus() {
                this.removeStatus(this.remove_item)
                    .then(res => {
                        if(res.status === 200) {
                            this.statusDeleteModal()
                            this.getAllStatuses()
                                .then(res => {
                                    if(res.status === 200) {
                                        this.allStatuses = res.data.success
                                    }
                                })
                        }
                    })
            },
            getLeadType(type) {
                switch (type) {
                    case 1: return 'seller';
                    case 0: return 'buyer';
                    default: return 'all leads'
                }
            }
        }
    }
</script>

<style scoped lang="scss">
.page {
    &_container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 1700px;
        width: 100%;
        margin: 0 auto;
        transition: .4s;
        &.straight_length {
            max-width: 1600px;
            transition: .4s;
        }
    }

    &_head {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 30px 30px 5px;

        &-item {
            display: flex;
            align-items: center;
        }

        &-title {
            color: $main_blue;
            font-size: 25px;
            font-weight: 500;
            letter-spacing: 0;
            text-transform: uppercase;
        }

        &-new-btn {
            width: 176px;
            height: 44px;
            border-radius: 5px;
            background: $light_blue;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            border: none;

            &:focus {
                box-shadow: none;
            }

            &:active {
                background: $main_blue !important;
            }
        }
    }

    &_content {
        width: 100%;
        padding: 30px 30px 0;

        ::v-deep table {
            border: 1px solid #dee2e6;

            td {
                vertical-align: middle;
            }
        }

        ::v-deep .table thead th {
            color: $main_blue;
            text-transform: uppercase;
            text-align: center;
        }

        & .item {
            &_name {

            }

            &_center {
                display: flex;
                justify-content: center;
            }

            &_view {
                width: 30px;
                height: 20px;
                background: url("~@/assets/images/view_icon.svg") center no-repeat;
                background-size: contain;
                margin: 0 auto;
                cursor: pointer;
            }
            &_edit {
                width: 30px;
                height: 20px;
                background: url("~@/assets/images/edit_icon.svg") center no-repeat;
                background-size: contain;
                margin: 0 auto;
                cursor: pointer;
            }


            &_delete {
                width: 24px;
                height: 30px;
                background: url("~@/assets/images/trash_icon.svg") center no-repeat;
                background-size: contain;
                margin: 0 auto;
                cursor: pointer;
            }
        }
    }
}
.status {
    &_modal_content {
        position: relative;
        padding: 0 20px;
        width: 540px;
        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: #B4BABC;
        }
        & .input_content {
            display: flex;
            flex-direction: column;
            & .single_input {
                & .input_item {
                    padding: 5px 0;
                    width: 100%;
                    & .select_arrow {
                        position: relative;
                        & .arrow_down {
                            position: absolute;
                            top: 14px;
                            right: 15px;
                            width: 14px;
                            height: 10px;
                            pointer-events: none;
                            background: url("~@/assets/images/arrow_down.svg") right  no-repeat;
                        }
                    }
                    & label {
                        color: $black;
                        font-weight: 500;
                        font-size: 16px;
                    }
                    & input {
                        border: 1px solid #798387;
                        border-radius: 5px;
                        &:focus {
                            box-shadow: none;
                            outline: none;
                        }
                    }
                    & select {
                        /*background: url("~@/assets/images/arrow_down.svg") right  no-repeat;*/
                        background: none;
                        border-radius: 4px;
                        padding-right: 5px;
                    }
                }
            }
        }
        & .done_btn-container {
            display: flex;
            justify-content: center;
            padding: 20px 0 10px;
            & .done_btn {
                background: $light_blue;
                box-shadow: 0 3px 6px #00000029;
                border-radius: 5px;
                width: 100%;
                height: 50px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: $white;
                font-weight: 400;
                font-size: 18px;
                border: none;
            }
        }
    }
    &_title {
        width: 100%;
        font-size: 25px;
        font-weight: 500;
        color: $main_blue;
        padding: 20px 0 10px;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
    }
}
.status-delete {
    &_modal_content {
        position: relative;
        padding: 0 20px;
        width: 560px;

        & .x_btn {
            position: absolute;
            top: 0;
            right: 0;
            width: 25px;
            height: 25px;
            cursor: pointer;
            color: #B4BABC;
        }

        & .input_content {
            display: flex;
            flex-direction: column;
        }
        & .delete_btn {
            background: $light_blue;
            box-shadow: 0 3px 6px #00000029;
            border-radius: 5px;
            width: 50%;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-weight: 400;
            font-size: 18px;
            border: none;
            &-container {
                display: flex;
                justify-content: center;
                padding: 20px 0 10px;
            }
        }
    }

    &_message {
        padding: 20px 30px 40px;
        color: $grey;
        font-size: 22px;
    }

    &_title {
        width: 100%;
        font-size: 25px;
        font-weight: 500;
        color: $main_blue;
        padding: 20px 0 10px;
        display: flex;
        justify-content: center;
        text-transform: uppercase;
    }

    &_description {
        padding: 15px 0;
        display: flex;
        width: 100%;
        min-height: 150px;
    }

    &_footer-container {
        display: flex;
        justify-content: center;
        padding: 20px 0 10px;
        width: 100%;
        height: 60px;
        background: url("~@/assets/images/rows.svg") center no-repeat;
        background-size: contain;
    }
}
::v-deep .modal-content {
    border: none !important;
    width: 600px;
    ::v-deep .modal-body {
        width: 580px !important;
    }
}
</style>