import { render, staticRenderFns } from "./LeadStatuses.vue?vue&type=template&id=5495c666&scoped=true&"
import script from "./LeadStatuses.vue?vue&type=script&lang=js&"
export * from "./LeadStatuses.vue?vue&type=script&lang=js&"
import style0 from "./LeadStatuses.vue?vue&type=style&index=0&id=5495c666&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5495c666",
  null
  
)

export default component.exports